export const menu = [
  {
    url: "/",
    name: "Главная",
  },
  {
    url: "/portfolio/",
    name: "Портфолио",
  },
  {
    url: "/vocations/",
    name: "Вакансии",
  },
  {
    url: "/contacts/",
    name: "Контакты",
  },
];
